import React, { useEffect } from 'react';

export default function TradingViewWidget2({ coin, coinIni }) {
  useEffect(() => {
    const script = document.createElement("script");
    const scriptText = document.createTextNode(`{
      "symbols": ["`+coin+`USD|1M"],
      "chartOnly": true,
      "isTransparent": true,
      "width": "100%",
      "height": "100%",
      "locale": "en",
      "colorTheme": "light",
      "autosize": false,
      "showVolume": false,
      "showMA": false,
      "hideDateRanges": true,
      "hideMarketStatus": true,
      "hideSymbolLogo": false,
      "scalePosition": "right",
      "scaleMode": "Normal",
      "noTimeScale": false,
      "valuesTracking": "1",
      "changeMode": "price-and-percent",
      "chartType": "area",
      "maLineColor": "#2962FF",
      "maLineWidth": 1,
      "maLength": 9,
      "lineWidth": 2,
      "lineType": 0,
      "gridLineColor": "rgba(120, 123, 134, 0.45)",
      "fontColor": "#101828",
      "dateRanges": [
        "1m|30"
      ],
      "lineColor": "rgba(72, 128, 255, 1)",
      "topColor": "rgba(72, 128, 255, 0.26)",
      "bottomColor": "rgba(72, 128, 255, 0)",
      "downColor": "#f7525f",
      "upColor": "#22ab94",
      "borderUpColor": "#22ab94",
      "borderDownColor": "#f7525f",
      "wickUpColor": "#22ab94",
      "wickDownColor": "#f7525f",
      "enabled_features": ["horz_touch_drag_scroll", "vert_touch_drag_scroll", "show_zoom_and_move_buttons_on_touch", "pinch_scale"]
    }`);
    script.type = 'text/javascript';
    script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-symbol-overview.js'
    script.async = true;
    script.appendChild(scriptText);
    script.id="terererrer"
    if(coinIni !== "") {
        const ele = document.getElementById('mainViewArea2');
        const remEle = document.getElementById('terererrer');
        ele.removeChild(remEle)
    }
    document.getElementById('mainViewArea2').appendChild(script);
  }, [coin])

  return (
    <div class="tradingview-widget-container" id="mainViewArea2" style={{ height: "100%", width: "100%", backgroundColor: "transparent" }}>
        <div class="tradingview-widget-copyright"><a href="https://www.tradingview.com/" rel="noopener nofollow" target="_blank"><span style={{ color: "#667085", fontSize: "13px", marginTop: "20px" }}>Charts by TradingView</span></a></div>
      <div class="tradingview-widget-container__widget" style={{ height: "100%", width: "100%", backgroundColor: "transparent" }}></div>
    </div>
  )
}
