import { Flex, Image, Text, Menu,
    MenuButton,
    MenuList,
    MenuItem,
    background, } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import logo from "../assets/images/favicon.png"
import { useEffect, useState } from "react";
import ngn from "../assets/images/nigeria.png"
import civ from "../assets/images/ivory-coast.png"

export default function NavBar({ iniColor, setLang, lang }) {
    const menu = [lang === "fr" ? "À propos de nous" : "About us", "Contact"]
    const [country, setCountry] = useState(lang === "fr" ? "CIV" : "Nigeria")

    const changeLanguage = (country) => {
        setNav(false)
        setCountry(country)
        setLang(country === "CIV" ? "fr" : "en")
        localStorage.setItem("isLangSet", country === "CIV" ? "fr" : "en")
    }

    const [nav, setNav] = useState(false)

    return (
        <>
        <Flex w="100%" justify="center" borderBottom="1px solid #F2F4F75C">
            <Flex className="container" px={["5", "3%"]}>
                <Flex bg="transparent" pt={["8px", "20px"]} pb={["8px", "18px"]} align="center" w="100%">
                    <Flex align="center" cursor="pointer" onClick={() => {
                        return window.scrollTo({
                            top: 0,
                            behavior: 'smooth',
                        })
                    }}>
                        <Text mr="2px" color={iniColor === "#fff" ? "#fff" : "#101828"} fontWeight="500" fontSize="26.38px" transition="300ms ease-in-out" className="spoof">Zine</Text>
                        <Image src={logo} h="18.11px" />
                    </Flex>

                    <Flex flex="1" justify="flex-end" display={["flex", "none"]}>
                        <Text transition="300ms ease-in-out" cursor="pointer" fontSize="24px" color={iniColor} onClick={() => nav ? setNav(false) : setNav(true)}><i className={nav ? "mdi mdi-close" : "mdi mdi-menu"}></i></Text>
                    </Flex>

                    <Flex flex="1" ml="12" align="center" display={["none", "flex"]}>
                        <Flex align="center">
                            {
                                menu.map((item, index) => (
                                    <Flex align="center" mr="12" cursor="pointer" fontWeight="500" color={iniColor} transition="300ms ease-in-out" key={index} _hover={{ color: iniColor !== "#fff" && "#6421F2", letterSpacing: "2px", fontWeight: "600" }} onClick={() => {
                                        if(index === 0) {
                                            const element = document.getElementById('aboutPage')
                                            return window.scrollTo({
                                                top: element ? element.offsetTop - 0 : 0,
                                                behavior: 'smooth',
                                            })
                                        }
                                        else if(index === 1) {
                                            const element = document.getElementById('contactPage')
                                            return window.scrollTo({
                                                top: element ? element.offsetTop - 50 : 0,
                                                behavior: 'smooth',
                                            })
                                        }
                                    }}>
                                        <Text>{item}</Text>
                                    </Flex>
                                ))
                            }
                        </Flex>

                        <Flex display={["none", "flex"]} transition="300ms ease-in-out" ml="auto" border={"1px solid "+iniColor} borderRadius="8px" padding="10px" align="center" fontSize="14px" cursor="pointer" onClick={() => document.getElementById('menuActor').click()}>
                            <Flex mr="3" w="21px" h="16px" backgroundImage={lang === "fr" ? civ : ngn} backgroundSize="cover" backgroundRepeat="no-repeat"></Flex>
                            <Menu>
                                <MenuButton>
                                    <Text color={iniColor} id="menuActor">{lang === "fr" ? "Côte d'Ivoire" : "Nigeria"}</Text>
                                </MenuButton>
                                <MenuList>
                                    <MenuItem onClick={() => changeLanguage("Nigeria")}>Nigeria</MenuItem>
                                    <MenuItem onClick={() => changeLanguage("CIV")}>Côte d'Ivoire</MenuItem>
                                </MenuList>
                            </Menu>
                            <Text pl="8">
                                <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.99846 6.41765C5.80375 6.41803 5.61505 6.35022 5.46513 6.22598L0.465125 2.05931C0.110742 1.76476 0.0622398 1.2387 0.356792 0.884314C0.651344 0.529932 1.17741 0.481429 1.53179 0.775981L5.99846 4.50931L10.4651 0.909314C10.6373 0.769506 10.8581 0.70409 11.0786 0.727551C11.2991 0.751013 11.5012 0.861415 11.6401 1.03431C11.7944 1.20753 11.8695 1.43721 11.8474 1.66811C11.8252 1.89901 11.7078 2.11024 11.5235 2.25098L6.52346 6.27598C6.36922 6.38058 6.18438 6.43046 5.99846 6.41765Z" fill={iniColor} />
                                </svg>
                            </Text>
                        </Flex>

                        <Flex display={["none", "flex"]} ml="6" bg="#071827" transition="200ms ease-in-out" _hover={{ bg: "#6421F2" }} padding="10px 18px" borderRadius="8px" fontWeight="600" justify="center" align="center" color="#fff" cursor="pointer" onClick={() => {
                            if(window.navigator.userAgent.includes("iPhone")) {
                                window.open("https://apps.apple.com/ng/app/", "_BLANK")
                            }
                            else {
                                window.open("https://play.google.com/store/apps/", "_BLANK")
                            }
                        }}>{lang === "fr" ? "Commencer" : "Get Started"}</Flex>
                    </Flex>
                </Flex>
            </Flex>
        </Flex>
        {
            nav &&
            <Flex w="100%" display={["flex", "none"]} direction="column" bg="#fff" px="5" data-aos="fade-down" data-aos-duration="500" py="8">
                <Flex direction="column" mb="4">
                    {
                        menu.map((item, index) => (
                            <Flex align="center" mb="3" pb="3" borderBottom="1px solid rgb(220,220,220)" cursor="pointer" fontWeight="500" color="#667085" transition="300ms ease-in-out" key={index} _hover={{ color: "#6421F2", letterSpacing: "2px", fontWeight: "600" }} onClick={() => {
                                setNav(false)
                                if(index === 0) {
                                    const element = document.getElementById('aboutPage')
                                    return window.scrollTo({
                                        top: element ? element.offsetTop + 150 : 0,
                                        behavior: 'smooth',
                                    })
                                }
                                else if(index === 1) {
                                    const element = document.getElementById('contactPage')
                                    return window.scrollTo({
                                        top: element ? element.offsetTop - 25 : 0,
                                        behavior: 'smooth',
                                    })
                                }
                            }}>
                                <Text mt="2">{item}</Text>
                            </Flex>
                        ))
                    }
                </Flex>

                <Flex mb="3" transition="300ms ease-in-out" mr="auto" border={"1px solid #667085"} borderRadius="8px" padding="10px" align="center" fontSize="14px" cursor="pointer" onClick={() => document.getElementById('menuActor2').click()}>
                    <Flex mr="3" w="21px" h="16px" backgroundImage={lang === "fr" ? civ : ngn} backgroundSize="cover" backgroundRepeat="no-repeat"></Flex>
                    <Menu>
                        <MenuButton>
                            <Text color="#667085" id="menuActor2">{lang === "fr" ? "Côte d'Ivoire" : "Nigeria"}</Text>
                        </MenuButton>
                        <MenuList>
                            <MenuItem onClick={() => changeLanguage("Nigeria")}>Nigeria</MenuItem>
                            <MenuItem onClick={() => changeLanguage("CIV")}>Côte d'Ivoire</MenuItem>
                        </MenuList>
                    </Menu>
                    <Text pl={["12", "8"]}>
                        <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.99846 6.41765C5.80375 6.41803 5.61505 6.35022 5.46513 6.22598L0.465125 2.05931C0.110742 1.76476 0.0622398 1.2387 0.356792 0.884314C0.651344 0.529932 1.17741 0.481429 1.53179 0.775981L5.99846 4.50931L10.4651 0.909314C10.6373 0.769506 10.8581 0.70409 11.0786 0.727551C11.2991 0.751013 11.5012 0.861415 11.6401 1.03431C11.7944 1.20753 11.8695 1.43721 11.8474 1.66811C11.8252 1.89901 11.7078 2.11024 11.5235 2.25098L6.52346 6.27598C6.36922 6.38058 6.18438 6.43046 5.99846 6.41765Z" fill="#667085" />
                        </svg>
                    </Text>
                </Flex>

                <Flex w="100%" bg="#071827" transition="200ms ease-in-out" _hover={{ bg: "#6421F2" }} padding="10px 18px" borderRadius="8px" fontWeight="600" justify="center" align="center" color="#fff" cursor="pointer" onClick={() => {
                    if(window.navigator.userAgent.includes("iPhone")) {
                        window.open("https://apps.apple.com/ng/app/", "_BLANK")
                    }
                    else {
                        window.open("https://play.google.com/store/apps/", "_BLANK")
                    }
                }}>{lang === "fr" ? "Commencer" : "Get Started"}</Flex>
            </Flex>
        }
        </>
    )
}

