import { Flex, Image, Text } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import logoWhite from "../assets/images/logoWhite.svg"
import s1 from "../assets/images/s1.png"
import s2 from "../assets/images/s2.png"
import s3 from "../assets/images/s3.png"
import s4 from "../assets/images/s4.png"


export default function Footer({ lang }) {
    const history = useHistory()
    const menu = [s1, s2, s3, s4]
    const menu2 = [lang === "fr" ? "À propos de nous" : "About us", "Contact"]
    return (
        <Flex w="100%" justify="center" bg="#4E0DD9" color="#fff">
            <Flex className="container" px={["5", "3%"]}>
                <Flex w="100%" py={["40px", "80px"]} direction="column">
                    <Flex w="100%" justify="space-between" align="center">
                        <Flex cursor="pointer" onClick={() => {
                            return window.scrollTo({
                                top: 0,
                                behavior: 'smooth',
                            })
                        }}>
                            <Image w={["74px", "101.33px"]} src={logoWhite} />
                        </Flex>
                        <Flex direction="column">
                            <Text display={["none", "block"]} fontSize="16px"><Text as="span" mr="1" fontSize="20px"><i className="mdi mdi-map-marker"></i></Text> {lang === "fr" ? "Apt B2 Rue F 38,  Rue Principale M’Badon, Cocody, Abidjan" : "22 Omotayo Ojo street, off Allen Avenue Ikeja"}</Text>
                            <Flex align="center" ml="auto" mt={["0", "7"]}>
                                {
                                    menu.map((item, index) => (
                                        <Image cursor="pointer" h="19.96px" src={item} key={index} mr={[index !== 3 && "4", index !== 3 && "6"]} />
                                    ))
                                }
                            </Flex>
                        </Flex>
                    </Flex>

                    <Text mt="8" display={["block", "none"]} fontSize="14px" textAlign="left"><Text as="span" mr="1" fontSize="20px"><i className="mdi mdi-map-marker"></i></Text>  {lang === "fr" ? "Apt B2 Rue F 38,  Rue Principale M’Badon, Cocody, Abidjan" : "22 Omotayo Ojo street, off Allen Avenue Ikeja"}</Text>
                    
                    <Flex w="100%" bg="#7F48F4" h="1px" my={["24px", "60px"]}></Flex>

                    <Flex w="100%" justify="space-between" align="center" direction={["column-reverse", "row"]}>
                        <Text mt={["8", "0"]} w={["100%", "auto"]} mr={["0", "0"]} fontSize="14px">{lang === "fr" ? "Portefeuille Zine @ 2024. Tous droits réservés." : "Zine Wallet @ 2024. All rights reserved."}</Text>

                        <Flex w={["100%", "auto"]} direction="column" align={["flex-start", "flex-end"]}>
                            <Flex w={["100%", "auto"]} align="center" fontSize={["14px", "16px"]}>
                                {
                                    menu2.map((item, index) => (
                                        <Text cursor="pointer" key={index} mr={[index !== 1 && "5", index !== 1 && "8"]} transition="300ms ease-in-out" _hover={{ letterSpacing: "2px", fontWeight: "600" }} onClick={() => {
                                            if(index === 0) {
                                                const element = document.getElementById('aboutPage')
                                                return window.scrollTo({
                                                    top: element ? element.offsetTop - 0 : 0,
                                                    behavior: 'smooth',
                                                })
                                            }
                                            else if(index === 1) {
                                                const element = document.getElementById('contactPage')
                                                return window.scrollTo({
                                                    top: element ? element.offsetTop - 50 : 0,
                                                    behavior: 'smooth',
                                                })
                                            }
                                        }}>{item}</Text>
                                    ))
                                }
                            </Flex>
                            <Text mt={["8", "5"]} fontSize={["14px", "16px"]}>{lang === "fr" ? "Une filiale de Sofdia Systems" : "A subsidiary of Sofdia Systems"}</Text>
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>
        </Flex>
    )
}