import axios from "axios";
import Toast from "../components/notify";
import { useHistory } from "react-router-dom";
import { useEffect } from "react";


export async function AuthConnect(type, url, data) {
  //AXIOS CLIENT HEADER
  const authToken = sessionStorage.getItem("ZineToken");
  const client = axios.create({
    baseURL: process.env.REACT_APP_ENQUIRY,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
  });
  try {
    const response =
      type === "post"
        ? await client.post(url, data)
        :  type === "put"
        ? await client.put(url, data)
        : type === "delete"
        ? await client.delete(url)
        : await client.get(url);
    return response.data;
  } catch (error) {
    if (error.response?.status === 401) {
      Toast("Hello your session has timed out, Kindly login again", "error");
      setTimeout(() => {
        sessionStorage.clear();
        window.location.replace("/");
      }, 3000);
    } else if (error?.response?.status) {
      if(error?.response?.status !== 404) { 
        if(error?.response?.status === 422) {
          Toast(error.response.data.errors[0]?.details[0], "error");
        }
        else {
          Toast(error.response.data.errors[0].message, "error");
        }
      }
    } else {
      Toast("Cannot connect at the moment, please try again", "error");
    }
  }
}



export const requireAuth = (Component) => {
    return (props) => {
      const auth = sessionStorage.getItem("ZineAuth");
      const history = useHistory();
  
      useEffect(() => {
        if (auth === null) {
          history.replace("/");
        }
      }, []);
  
      return <Component {...props} />;
    };
};


export async function CoinMarketCap(type, url, data) {
    const client = axios.create({
      baseURL: process.env.REACT_APP_SERVER_URL,
      responseType: 'stream',
    });
    try {
      const response =
      type === "post" ? await client.post(url, data) : await client.get(url);
      response.on('data', data => {
        console.log(data);
      });
      response.on('end', () => {
          console.log("stream done");
      });
    } catch (error) {
      if (error.response?.status) {
        console.log(error.response.data.message);
      } else {
        console.log("Cannot connect at the moment, please try again");
      }
    }
  }

  export const GetCoinMarketCap = async (url, setCArr, setLoading) => {
    if(typeof(EventSource) !== "undefined") {
      var source = new EventSource(process.env.REACT_APP_SERVER_URL+url);
      source.onmessage = function(event) {
        const nArr = JSON.parse(event.data)
        setCArr(nArr)
        setLoading(false)
      }
      source.onerror = function(error) {
        console.error(error)
        setLoading(false)
      }
    } else {
    try {
      const response = await fetch(process.env.REACT_APP_SERVER_URL+url, {
        method: 'GET',
        headers: {
          Accept: 'text/event-stream',
        },
      });
      const reader = response.body.getReader();
      while (true) {
        const { done, value } = await reader.read();
        if (done) {
          break;
        }
        const nArr = new TextDecoder().decode(value)
        console.log(nArr)
      }
    } catch (error) {
      console.error(error);
    }
  }
  };